const Images = {
  ai_icon_1: require("./ai_1.png"),
  ai_icon_2: require("./ai_2.png"),
  ai_icon_3: require("./ai_3.png"),
  ai_icon_4: require("./ai_4.png"),
  ai_icon_5: require("./ai_5.png"),
  ai_icon_6: require("./ai_6.png"),
  nav_icon_1: require("./hd_1.png"),
  nav_icon_2: require("./hd_2.png"),
  nav_icon_3: require("./hd_3.png"),
  nav_icon_4: require("./hd_4.png"),
  nav_icon_5: require("./hd_5.png"),
  nav_icon_6: require("./hd_6.png"),
};
export default Images;

import React from "react";
import locationImg from "../../Assets/Images/locations.png";
import { Col, Row } from "react-bootstrap";

const Locations = () => {
  return (
    <div className="locations_page common_bottom_margin overflow-hidden">
      <Row>
        <Col xl="6">
          <img src={locationImg} alt="location" className="img-fluid" />
        </Col>
        <Col xl="6" className="m-auto pe-5">
          <div className="locations_content">
            <h2 className="page_heading">See locations</h2>
            <p>
              Express yourself without words. Use stickers and GIFs or share
              everyday moments on Status. Record a voice message for a quick
              hello or a longer story.
            </p>
            <p>
              Express yourself without words. Use stickers and GIFs or share
              everyday moments on Status. Record a voice message for a quick
              hello or a longer story. Express yourself without words. Use
              stickers and GIFs or share everyday moments on Status. Record a
              voice message for a quick hello or a longer story.
            </p>
            <button className="common_btn">Read More</button>
          </div>{" "}
        </Col>
      </Row>
    </div>
  );
};

export default Locations;

import React from "react";
import Images from "../../Assets/Images/index";
import { Col, Container, Row } from "react-bootstrap";
import SmartAiImg from "../../Assets/Images/smart_ai.png";

const SmartAi = () => {
  const row_data = [
    {
      img: Images.ai_icon_1,
      heading: "Audio and HD video calling",
      content:
        "Experience crystal clear audio and HD video in one to one or group calls – now with call reactions too.",
    },
    {
      img: Images.ai_icon_2,
      heading: "Reactions",
      content:
        "Experience crystal clear audio and HD video in one to one or group calls – now with call reactions too.",
    },
    {
      img: Images.ai_icon_3,
      heading: "Avatar",
      content:
        "Experience crystal clear audio and HD video in one to one or group calls – now with call reactions too.",
    },
  ];
  const row_data2 = [
    {
      img: Images.ai_icon_4,
      heading: "Director View",
      content:
        "Experience crystal clear audio and HD video in one to one or group calls – now with call reactions too.",
    },
    {
      img: Images.ai_icon_5,
      heading: "Filters",
      content:
        "Experience crystal clear audio and HD video in one to one or group calls – now with call reactions too.",
    },
    {
      img: Images.ai_icon_6,
      heading: "Transcript",
      content:
        "Experience crystal clear audio and HD video in one to one or group calls – now with call reactions too.",
    },
  ];

  return (
    <div className="smart_ai_page common_bottom_margin">
      <Container>
        <div className="smart_ai_content">
          <h1 className="page_heading_h1">
            <span className="heading_span">SMART AI</span> VIDEO
          </h1>
        </div>
        <Row className="justify-content-center align-items-center gy-4">
          <Col xl="4" className="gap-4 card_col">
            {row_data.map((item, i) => (
              <div className="smart_ai_card" key={i}>
                <div className="smart_ai_icon">
                  <img src={item?.img} alt="icon" />
                </div>
                <div className="smart_ai_card_content">
                  <p className="smart_ai_card_heading">{item?.heading}</p>
                  <p className="smart_ai_card_text">{item?.content}</p>
                </div>
              </div>
            ))}
          </Col>
          <Col xl="4">
            <img src={SmartAiImg} alt="smart ai" className="img-fluid" />
          </Col>
          <Col xl="4" className="gap-4 card_col">
            {row_data2.map((item, i) => (
              <div className="smart_ai_card" key={i}>
                <div className="smart_ai_icon">
                  <img src={item?.img} alt="icon" />
                </div>
                <div className="smart_ai_card_content">
                  <p className="smart_ai_card_heading">{item?.heading}</p>
                  <p className="smart_ai_card_text">{item?.content}</p>
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SmartAi;

import React from "react";
import GooglePlayImg from "../../Assets/Images/googlePlay.png";
import AppleStoreImg from "../../Assets/Images/appleStore.png";
import BannerBg from "../../Assets/Images/banner_bg.gif";
import BannerImg from "../../Assets/Images/banner.gif";
import { Container } from "react-bootstrap";

const Banner = () => {
  return (
    <div className="banner_page common_bottom_margin">
      <div className="banner_main_content">
        <Container>
          <img
            src={BannerBg}
            alt="banner"
            className="img-fluid banner_bg_gif_img"
          />
          <div className="banner_content">
            <h2 className="page_heading">
              CONNECT WITH YOUR <br />
              <span className="heading_span">FRIENDS EASILY</span>
            </h2>
            <p className="common_p">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
              vitae laoreet arcu. Donec mi tortor,
              <br /> lacinia ac metus a, tincidunt mattis sem. In mattis
              ultricies ante nec consectetur. Quisque eu laoreet velit.
            </p>
            <div className="app_images">
              <img className="mx-2" src={AppleStoreImg} alt="apple store app" />
              <img className="mx-2" src={GooglePlayImg} alt="google play app" />
            </div>
          </div>
          <img
            src={BannerImg}
            alt="banner"
            className="banner_bottom_img img-fluid"
          />
        </Container>
      </div>
    </div>
  );
};

export default Banner;

import React from "react";
import logo from "../../Assets/Images/logo.png";
import appleStore from "../../Assets/Images/appleStore.png";
import googlePlay from "../../Assets/Images/googlePlay.png";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  const data = [
    {
      heading: "Useful links",
      listContent: [
        "Features",
        "Privacy",
        "Help Center",
        "Payment",
        "Ecommerce",
        "Contact Us",
      ],
    },
    {
      heading: "Who we are",
      listContent: ["About Us", "Careers", "Help Center"],
    },
    {
      heading: "Use WhatsApp",
      listContent: ["Android", "Iphone"],
    },
  ];
  return (
    <div className="footer_page">
      <Container>
        <Row className="py-5">
          <Col xl="4">
            <img src={logo} alt="logo" />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
            <div className="d-flex">
              <img
                src={appleStore}
                alt="apple store"
                className="img-fluid mx-2"
              />
              <img
                src={googlePlay}
                alt="google play"
                className="img-fluid mx-2"
              />
            </div>
          </Col>
          <Col xl="8" className="m-auto">
            <Row>
              {data?.map((item, i) => (
                <Col xl="4" key={i} className="footer_ul">
                  <div>
                    <h4 key={i}>{item?.heading}</h4>
                    {item?.listContent?.map((listItem, index) => (
                      <p key={index}>{listItem}</p>
                    ))}
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="footer_bottom_div">
        <p>2024 © ATLAS SYNC </p>
        <p>| </p>
        <p>Terms & Privacy Policy </p>
        <p>| </p>
        <p>Sitemap</p>
      </div>
    </div>
  );
};

export default Footer;

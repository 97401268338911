import React from "react";
import PhoneImg from "../../Assets/Images/staus_phone.png";
import StatusImg from "../../Assets/Images/status.png";
import { Col, Container, Row } from "react-bootstrap";

const Status = () => {
  return (
    <div className="status_page common_bottom_margin">
      <Container>
        <Row>
          <Col xl={3}>
            <img src={PhoneImg} alt="phone" className="img-fluid" />
          </Col>
          <Col xl={9} className="status_right_content">
            <Row className="status_inner_content">
              <Col xl="6">
                <div>
                  <h2 className="page_heading">Say what you feel</h2>
                  <p>
                    Express yourself without words. Use stickers and GIFs or
                    share everyday moments on Status. Record a voice message for
                    a quick hello or a longer story.
                  </p>
                  <button className="common_btn">Read More</button>
                </div>
              </Col>
            </Row>
            <div className="status_back_img">
              <img src={StatusImg} alt="background" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Status;

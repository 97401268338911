import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import featuresImg from "../../Assets/Images/feature_1.png";

const Features = () => {
  return (
    <div className="features_page common_bottom_margin">
      <Container>
        <Row style={{ marginbottom: "50px" }}>
          <Col xl="5">
            <div className="features_text_content">
              <h2 className="page_heading">
                GET MOST OUT OF <span className="heading_span">ATLAS SYNC</span>
              </h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Maecenas vitae laoreet arcu.
              </p>
            </div>
          </Col>
        </Row>
        <div className="feature_content">
          <Row style={{ marginBottom: "100px" }}>
            <Col xl={5}>
              <div className="feature_card">
                <Row>
                  <Col xl={3} className="m-auto text-center">
                    <img src={featuresImg} alt="feature" />
                  </Col>
                  <Col xl={9}>
                    <p>
                      <span className="feature_card_heading">
                        Audio and HD video calling
                      </span>
                      <br />
                      Experience crystal clear audio and HD video in one to one
                      or group calls – now with call reactions too.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xl={{ span: 5, offset: 2 }}>
              <div className="feature_card">
                <Row>
                  <Col xl={3} className="m-auto text-center">
                    <img src={featuresImg} alt="feature" />
                  </Col>
                  <Col xl={9}>
                    <p>
                      <span className="feature_card_heading">
                        Audio and HD video calling
                      </span>
                      <br />
                      Experience crystal clear audio and HD video in one to one
                      or group calls – now with call reactions too.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={5}>
              <div className="feature_card">
                <Row>
                  <Col xl={3} className="m-auto text-center">
                    <img src={featuresImg} alt="feature" />
                  </Col>
                  <Col xl={9}>
                    <p>
                      <span className="feature_card_heading">
                        Audio and HD video calling
                      </span>
                      <br />
                      Experience crystal clear audio and HD video in one to one
                      or group calls – now with call reactions too.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xl={{ span: 5, offset: 2 }}>
              <div className="feature_card">
                <Row>
                  <Col xl={3} className="m-auto text-center">
                    <img src={featuresImg} alt="feature" />
                  </Col>
                  <Col xl={9}>
                    <p>
                      <span className="feature_card_heading">
                        Audio and HD video calling
                      </span>
                      <br />
                      Experience crystal clear audio and HD video in one to one
                      or group calls – now with call reactions too.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <div className="text-center" style={{ padding: "100px 0px" }}>
          <button className="common_btn">See All Featres</button>
        </div>
      </Container>
    </div>
  );
};

export default Features;

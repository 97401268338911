import React from "react";
import "./landingPage.css";
import Banner from "./banner";
import SmartAi from "./smartAi";
import Locations from "./locations";
import Footer from "./footer";
import Status from "./status";
import Features from "./features";
import Header from "./header";

const index = () => {
  return (
    <React.Fragment>
      <Header />
      <Banner />
      <Features />
      <Status />
      <SmartAi />
      <Locations />
      <Footer />
    </React.Fragment>
  );
};

export default index;

import React from "react";
import hdphImg from "../../Assets/Images/hdph.png";
import mailImg from "../../Assets/Images/mail.png";
import header_logo from "../../Assets/Images/logo.png";
import { Container, Nav, Navbar } from "react-bootstrap";
import Images from "../../Assets/Images";

const Header = () => {
  const data = [
    {
      text: "Features",
      img: Images.nav_icon_1,
    },
    {
      text: "Privacy",
      img: Images.nav_icon_2,
    },
    {
      text: "Help Center",
      img: Images.nav_icon_3,
    },
    {
      text: "Payment",
      img: Images.nav_icon_4,
    },
    {
      text: "Ecommerce",
      img: Images.nav_icon_5,
    },
    {
      text: "Contact Us",
      img: Images.nav_icon_6,
    },
  ];
  return (
    <div className="header_page">
      <Container>
        <div className="top_content d-flex gap-4 justify-content-end my-4">
          <div className="d-flex gap-2 align-items-center">
            <img height={20} src={hdphImg} alt="hdph" />
            <p>
              Support
              <span className="fw-bold"> #444 000 0000</span>
            </p>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <img height={20} src={mailImg} alt="mail" />
            <p>
              <span className="fw-bold">Support.faction@gmail.com</span>
            </p>
          </div>
        </div>
        <div className="header_content">
          <Navbar collapseOnSelect expand="lg" className="p-0">
            <Navbar.Brand href="#home">
              <div className="d-flex align-items-center">
                <img src={header_logo} alt="header-logo" height={60} />
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ms-auto">
                {data.map((item, i) => (
                  <Nav.Link href="#" key={i} className="text-center mx-4">
                    <img height={20} src={item?.img} alt="nav-item" />
                    <p>{item?.text}</p>
                  </Nav.Link>
                ))}
              </Nav>
              <button className="common_btn">Download</button>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </Container>
    </div>
  );
};

export default Header;
